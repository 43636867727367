import React from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import { CarouselSnapped, CarouselSnappedSlide } from '@andes/carousel-snapped';

import Section from '../../../commons/section';
import withTracker from '../../../commons/with-tracker';
import InfoSlide from './info-slide';

function SiteShoppingInfoMobile({ elements, type, lazyLoad }) {
  elements.forEach((element) => {
    element.id = element.title_label;
    element.lazyLoad = lazyLoad;
  });
  return (
    <Section type={type}>
      <CarouselSnapped arrows={false} pagination={{ mode: 'light' }}>
        {elements.map((element) => (
          <CarouselSnappedSlide key={`site-shopping-${element.id}`}>
            <InfoSlide {...element} />
          </CarouselSnappedSlide>
        ))}
      </CarouselSnapped>
    </Section>
  );
}

SiteShoppingInfoMobile.propTypes = {
  elements: arrayOf(
    shape({
      title_label: string.isRequired,
    }),
  ).isRequired,
  type: string.isRequired,
  lazyLoad: bool.isRequired,
};

export default withTracker(SiteShoppingInfoMobile);
